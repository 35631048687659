import React from 'react'
import { MdxArticleFields } from '../models/mdx'
import EntryImage from './entryImage'
import EntryLink from './entryLink'
import EntryMeta from './entryMeta'

type DataProps = {
  articles: MdxArticleFields[]
}

const ArticlesList: React.FC<DataProps> = ({ articles }) => (
  <div className="container clearfix">
    <div className="col-12">
      <div className="fancy-title title-border">
        <h3>最新更新的博客文章</h3>
      </div>
      <div className="row posts-md col-mb-30">
        {articles.map((article, index) => (
          <div key={index} className="entry col-sm-6 col-md-4">
            <div className="grid-inner">
              <EntryImage article={article} />
              <div className="entry-title title-xs nott">
                <h3>
                  <EntryLink article={article}>
                    {article.frontmatter.title}
                  </EntryLink>
                </h3>
              </div>
              <EntryMeta article={article} />
              <div className="entry-content">
                <p className="mb-0">{article.frontmatter.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default ArticlesList
