import React from 'react'
import { MdxArticleFields } from '../models/mdx'
import { Link } from 'gatsby'
type DataProps = {
  article: MdxArticleFields
}

const EntryLink: React.FC<DataProps> = ({ article, children }) => (
  <Link to={`/${article.fields.slug}/`}>{children}</Link>
)

export default EntryLink
