import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { MdxArticleFields } from '../models/mdx'
import Layout from '../layout/layout'
import ArticlesList from '../components/articlesList'
import Section from '../components/section'

type DataProps = {
  allMdx: {
    nodes: MdxArticleFields[]
  }
}

const JavaPage : React.FC<PageProps<DataProps>> = ({
  data: {
    allMdx: { nodes },
  },
}) => (
  <Layout pageTitle="Java" pageSubTitle="Java 相关的技术内容">
    <Section transparent={true}>
      <ArticlesList articles={nodes} />
    </Section>
  </Layout>
)

export default JavaPage

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { category: { eq: "java" } } }, sort: {fields: frontmatter___published_at, order: DESC}) {
      nodes {
        ...MdxArticleFields
      }
    }
  }
`
