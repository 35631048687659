import React from 'react'

type DataProps = {
  transparent?: boolean
}

const Section: React.FC<DataProps> = ({ transparent, children }) => (
  <div className={`section m-0 ${transparent ? 'bg-transparent' : ''}`}>
    <div className="container clearfix">{children}</div>
  </div>
)

export default Section
