import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { MdxArticleFields } from '../models/mdx'

type DataProps = {
  article: MdxArticleFields
}

const EntryMeta: React.FC<DataProps> = ({ article }) => (
  <div className="entry-meta">
    <ul>
      <li>
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
        {article.frontmatter.published_at}
      </li>
      <li>
        <FontAwesomeIcon icon={faFolderOpen} className="mr-1" />
        {article.frontmatter.tags.map((tag, index) => (
          <span key={index} className="pr-1">{tag}</span>
        ))}
      </li>
    </ul>
  </div>
)

export default EntryMeta
