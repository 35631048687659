import React from 'react'
import Img from 'gatsby-image'
import { MdxArticleFields } from '../models/mdx'
import EntryLink from './entryLink'

type DataProps = {
  article: MdxArticleFields
}

const EntryImage: React.FC<DataProps> = ({ article }) => (
  <>
    {article.frontmatter.feature_image && (
      <div className="entry-image text-center">
        <EntryLink article={article}>
          <Img
            fixed={article.frontmatter.feature_image.childImageSharp.fixed}
          />
        </EntryLink>
      </div>
    )}
  </>
)

export default EntryImage
